import React from 'react'
import { Container } from 'reactstrap'
import Layout from '../components/layout'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Container className="py-lg-5 mt-5 mb-5 text-center">
      <h1>Kein Inhalt gefunden ...</h1>
      <p>Es tut uns leid, unter dieser Seite konnte kein Inhalt gefunden werden.</p>
    </Container>
  </Layout>
)

export default NotFoundPage